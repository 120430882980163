import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SidebarCommunity from '../../components/SidebarCommunity'

const EventsPage = () => (
  <Layout>
    <Helmet>
      <body className="community videos" />
    </Helmet>

    <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
         description="The tools you need to build Proof of Process apps." />

    <SidebarCommunity />

    <main className="content">
      <h2>Videos</h2>

      <div className="video">
        <iframe width="240" height="135" src="https://www.youtube.com/embed/cYrm6zNDlNU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a href="https://www.youtube.com/watch?v=cYrm6zNDlNU">Hashgraph Consensus Algorithm</a>
      </div>

      <div className="video">
        <iframe width="240" height="135" src="https://www.youtube.com/embed/FQfNPv5bvms" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a href="https://www.youtube.com/watch?v=FQfNPv5bvms">Byzantine Fault Tolerance Against Censorship</a>
      </div>

      <div className="video">
        <iframe width="240" height="135" src="https://www.youtube.com/embed/Rnl1g6IccpY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a href="https://www.youtube.com/watch?v=Rnl1g6IccpY">Schnorr Ring Signatures</a>
      </div>

    </main>
  </Layout>
)

export default EventsPage
